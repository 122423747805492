import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';

import {
  FilterModel, PartnerLocationListingModel,
  PagedModel, Store, PartnerModel, PartnerAttributes,
  GenericResponseModel, PartnerLocationModel, UserModel, PartnerContactModel, PartnerSMSModel, ReportRequest, PartnerWidgetModel
} from '../models';
import { environment } from '../../environments/environment';
import { Utils } from '../helpers/utils';
import { catchError } from 'rxjs/operators';
import { PlantDetailModel, PlantModel } from '../models/plant.model';

@Injectable({ providedIn: 'root' })
export class PartnersService extends Store<PartnerModel>{

  constructor(private http: HttpClient) {
    super(new PartnerModel());
  }

  searchPartners(filter: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/partners/0/Search/?` + Utils.qs(filter));
  }

  getPartner(id: number): Observable<PartnerModel> {
    return this.http.get<PartnerModel>(`${environment.apiUrl}/partners/${id}/`);
  }

  getPartnerWidget(id: number): Observable<PartnerWidgetModel> {
    return this.http.get<PartnerWidgetModel>(`${environment.apiUrl}/partners/${id}/Widget`);
  }

  updateRestrict(id: number, data: any): Observable<GenericResponseModel> {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/Restrict`, data);
  }

  savePartnerWidget(id: number, model: PartnerWidgetModel): Observable<PartnerWidgetModel> {
    return this.http.post<PartnerWidgetModel>(`${environment.apiUrl}/partners/${id}/Widget`, model);
  }

  getPartnerAttrs(id: number): Observable<PartnerAttributes> {
    return this.http.get<PartnerAttributes>(`${environment.apiUrl}/partners/${id}/Attributes`);
  }

  savePartner(model: PartnerModel): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${model.Id}/`, model);
  }

  deletePartner(id: number): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/`);
  }

  searchLocations(partnerId: number, filter: FilterModel): Observable<PagedModel<PartnerLocationListingModel>> {
    return this.http.get<PagedModel<PartnerLocationListingModel>>(`${environment.apiUrl}/partners/${partnerId}/Locations/?` + Utils.qs(filter));
  }

  deleteLocation(id: number, locId: string): Observable<GenericResponseModel> {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/locations/${locId}`);
  }

  getPartnerLocation(id: number, lid: string): Observable<PartnerLocationModel> {
    return this.http.get<PartnerLocationModel>(`${environment.apiUrl}/partners/${id}/locations/${lid}`);
  }

  saveLocation(id: number, lid: string, model: PartnerLocationModel): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/Locations/${lid}`, model);
  }

  validateAddress(id: number, model: PartnerLocationModel): Observable<PartnerLocationModel> {
    return this.http.post<PartnerLocationModel>(`${environment.apiUrl}/partners/${id}/ValidateAddress/`, model);
  }

  searchUsers(partnerId: number, filter: FilterModel): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.apiUrl}/partners/${partnerId}/users/?` + Utils.qs(filter));
  }

  getContacts(id: number): Observable<PartnerContactModel[]> {
    return this.http.get<PartnerContactModel[]>(`${environment.apiUrl}/partners/${id}/Contacts`);
  }

  saveContacts(id: number, contacts: PartnerContactModel[]): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/Contacts`, contacts);
  }

  getSMS(id: number): Observable<PartnerSMSModel> {
    return this.http.get<PartnerSMSModel>(`${environment.apiUrl}/partners/${id}/SMS`);
  }

  saveSMS(id: number, model: PartnerSMSModel): Observable<GenericResponseModel> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/SMS`, model);
  }

  getDashboardStats(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/partners/${id}/Stats/`)
      .pipe(
        catchError(err => {
          console.error(err);
          return EMPTY;
        })
      );
  }

  getPlants$(partnerId: number, filter: FilterModel): Observable<PagedModel<PlantDetailModel>> {
    return this.http.get<PagedModel<PlantDetailModel>>(`${environment.apiUrl}/partners/${partnerId}/plants/?` + Utils.qs(filter));
  }

  yardPerDayReport(id: number, filter: ReportRequest): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/partners/${id}/YardPerDayReport?` + Utils.qs(filter));
  }

  optInSubscribers(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/partners/${id}/OptinSubscribers`);
  }

  getDefaultOpenAIModel(): Observable<any> {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/query/`);
  }

  saveOpenAIModel(model: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/query/`, model);
  }

  testOpenAIModel(id: number, model: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/query/${id}/test`, model);
  }

  getDefaultTextOpenAISettings(): Observable<any> {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/query/0/TextSettings`);
  }

  saveDefaultTextOpenAISettings(request: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/query/0/TextSettings`, request);
  }

  testDefaultTextOpenAISettings(request: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/query/0/RunTextSettings`, request);
  }

  getOpenAIModels(): Observable<any> {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/query/0/Models`);
  }

  addPartnerPlant$(partnerId: number, plantId: number): Observable<GenericResponseModel> {
    const plantIds: number[] = [];
    plantIds.push(plantId);

    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${partnerId}/plants/`, { s: plantIds.join(",") });
  }

  searchPlants$(partnerId: number, filter: Object): Observable<PagedModel<PlantModel>> {
    return this.http.get<PagedModel<PlantModel>>(`${environment.apiUrl}/partners/${partnerId}/plantssearch/?` + Utils.qs(filter));
  }

  deletePartnerPlant$(partnerId: number, ids: number[] = []): Observable<GenericResponseModel> {
    const plantIds: number[] = [];
    if (ids.length > 0) {
      plantIds.push(...ids);
    }

    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/partners/${partnerId}/plants/`, { body: { s: plantIds.join(",") } });
  }

  generateQRCodes$(partnerId: number, ids: number[]): Observable<any> {
    return this.http.post(`${environment.apiUrl}/partners/${partnerId}/qrcodes/`, ids, { responseType: 'blob' as 'json' });
  }

  generateQRCode$(partnerId: number, plantId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/partners/${partnerId}/qrcodes/${plantId}`, { responseType: 'blob' as 'json' });
  }

  getTopics(): Observable<any> {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/common/0/ExpertTopics`);
  }

  runHealthAssessment(request: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/query/0/RunHealthAssessment`, request);
  }

  getAskExpertPreset(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/common/138DM5WYCEIY6TD/ControlCodes`);
  }

  getMetaTopics(): Observable<any> {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/common/0/ExpertAskMeta`);
  }

  searchReferralCodes(partnerId: number, filter: FilterModel): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/partners/${partnerId}/ReferralCodes/?` + Utils.qs(filter));
  }

  saveReferralCode(partnerId: number,request: any): Observable<any> {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/partners/${partnerId}/ReferralCode/`, request);
  }

  getReferralCode(partnerId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/partners/${partnerId}/ReferralCode/`);
  }
     
  deleteReferralCode(id: number): Observable<any> {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/partners/${id}/ReferralCode/`, {});
  }

  loadSubscriptions(partnerId: number): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/partners/${partnerId}/Subscriptions/`);
  }

  getReferralPerformance(partnerId: number, filter: any): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/partners/${partnerId}/ReferralPerformance/`,filter);
  }

  featurePlant$(partnerId: number, plantId: number, featured: boolean): Observable<GenericResponseModel> {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/partners/${partnerId}/featured/`, { Id: plantId, Featured: featured });
  }
}
